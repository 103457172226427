import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["sidebar", "close", "open"]

  connect() {
    const isHidden = localStorage.getItem("sidebarToggled") === "true"
    this.sidebarTarget.toggleAttribute("hidden", isHidden)
    this.openTarget.toggleAttribute("hidden", !isHidden)
  }

  toggle(event) {
    event.preventDefault()
    const isHidden = this.sidebarTarget.toggleAttribute("hidden")
    this.openTarget.toggleAttribute("hidden", !isHidden)
    localStorage.setItem("sidebarToggled", isHidden)
  }
}
